import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "personal-dashboards-for-confluence"
    }}>{`Personal Dashboards for Confluence`}</h1>
    <br />
    <p>{`Personal Dashboards is built on a simple concept: You can use any page as your Confluence dashboard.`}</p>
    <p>{`When you open the Dashboard Manager, the only thing you have to do is click the Add Page button`}</p>
    <Image width={'36em'} src='/public/PDTutorial.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`Simply search for a page - you can select a page from anywhere in Confluence.`}</p>
    <Image width={'36em'} src='/public/PageSearch.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`Now you can add the page as your default Confluence dashboard`}</p>
    <Image width={'36em'} src='/public/AddDashboard.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`When you navigate to the Confluence dashboard using the logo in the top left, the page will be shown alongside a Dashboard picker.`}</p>
    <p>{`You can use this select box to switch to your other dashboards or simply go back to the default Confluence dashboard.`}</p>
    <Image width={'90%'} src='/public/DBSwitcher.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 4
    }} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      